import { getRetailChains } from 'src/ajax/getRetailChains';
import { RetailChain } from 'src/redux/retailChain/types';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";

const emptyArray: RetailChain[] = [];

const useRetailChains = () => {
  const { fetchConfig } = useAppConfig();

  const retailChainsFetcher = () => getRetailChains(fetchConfig);

  const { data, error } = useSWR(
    ['retailChains'],
    retailChainsFetcher,
  );

  const chainsAreFetching = !data && !error;
  const chains = data && data.length ? data : emptyArray;

  return {
    chains,
    chainsAreFetching,
  };
};

export default useRetailChains;
