import { useRouter } from 'next/router';
import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import useAddProductTo from 'src/hooks/useAddProductTo/useAddProductTo';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import { useAppSelector } from 'src/redux/hooks';
import { getBadgeParamsFromProduct } from 'src/utils/product/getBadgeParamsFromProduct';
import { getProductTileImage } from 'src/utils/product/getProductTileImage';
import { Product } from '../../../redux/apiTypes';
import { selectCartItems } from '../../../redux/cart/general/cartSelectors';
import { placeProductToCart } from '../../../redux/cart/general/operations/placeProductToCart';
import updateAmount from '../../../redux/cart/general/operations/updateAmount';
import { trackProductClick } from '../../../redux/googleAnalytics/googleAnalyticsActions';
import { openModal } from '../../../redux/modals';
import openProductModalOrPage from '../../../redux/modals/openProductModalOrPage';
import { getInitialAmount } from '../../../utils/cart/getInitialAmount';
import { addToCartButtonsEnum, productClickEventEnum } from '../../../utils/marketing/enhancedEcommerce';
import ProductRow from '../../views/ProductRow/ProductRow';
import useFavorites from 'src/services/favorites/useFavorites';

interface Props {
  product: Product;
  index: number;
  addToHistory: () => void;
}

const SearchResultsProductRow: FC<Props> = props => {
  const {
    product, index, addToHistory,
  } = props;

  const {
    ean,
    title,
    unit,
    quantity,
    img,
    name,
    weight,
    volume,
    bundle,
    price,
    discount,
    currency,
    in_stock,
  } = product;

  const dispatch = useDispatch();
  const router = useRouter();

  const {
    getProductIsFavorite,
    toggleFavorite,
  } = useFavorites();

  const cartItem = useAppSelector(selectCartItems).find(i => i.ean === ean);
  const isFavorite = getProductIsFavorite(ean);

  const addressIsSet = !!useAppSelector(selectSelectedDelivery);
  const addProductTo = useAddProductTo();

  const place = addToCartButtonsEnum.SEARCH_BOX;
  const amount = cartItem?.amount;

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    addToHistory();
    dispatch(openProductModalOrPage({
      ean: product.ean,
      slug: product.slug,
      backUrl: router.asPath,
      ctrlKeyIsPressed: event.ctrlKey,
      metaKeyIsPressed: event.metaKey,
    }));

    dispatch(
      trackProductClick(product, productClickEventEnum.SEARCH_BOX, index),
    );
  };

  const handleAddToCart = () => {
    addToHistory();

    const addProductToCart = () => {
      const amount = getInitialAmount(unit, quantity, ean);

      dispatch(placeProductToCart({ ...product, amount }, place));
    };

    if (addressIsSet) {
      addProductTo(addProductToCart, product);
    } else {
      dispatch(openModal('addressModal', null, { onSetNewDelivery: addProductToCart }));
    }
  };

  const handleAmountChange = (newAmount: number) => {
    addToHistory();
    dispatch(updateAmount(newAmount, place, product));
  };

  const handleFavoriteClick = () => {
    addToHistory();
    toggleFavorite({
      product,
    });
  };

  return (
    <ProductRow
      imageSrc={getProductTileImage(img)}
      title={title || name}
      unit={unit}
      weight={weight}
      volume={volume}
      bundle={bundle}
      price={price}
      discount={discount}
      currency={currency}
      quantity={quantity}
      itemInStock={!!in_stock}
      badgeParams={getBadgeParamsFromProduct(product)}
      amount={amount}
      place={place}
      isFavorite={isFavorite}
      onClick={handleClick}
      onAddToCart={handleAddToCart}
      onChangeAmount={handleAmountChange}
      onFavoriteClick={handleFavoriteClick}
    />
  );
};

export default SearchResultsProductRow;
