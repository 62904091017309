import * as React from 'react';
import { Fragment } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import getFilterNamesWithCurrency from 'src/utils/filter/getFilterNamesWithCurrency';
import { Filter } from '../../../redux/apiTypes';
import { Facet } from '../../containers/CatalogActiveFiltersList/getActiveFilters';
import { HandleFilterFunc } from '../../containers/CatalogFilters/CatalogFilters';
import { DeleteFiltersFunc } from '../CatalogFiltersMobile/CatalogFiltersMobile';
import CheckList from '../CheckList/CheckList';
import { styles } from './CatalogFiltersMobileOptions.styles';

interface Props {
  eCommerceCategory: string;
  selectedFilterType: Filter;
  activeFilters: Facet[];
  isFetchingProducts: boolean;
  closeCategory(): void;
  handleFilter: HandleFilterFunc;
  deleteFilters: DeleteFiltersFunc;
  excludeParams: string[];
}

const CatalogFiltersMobileOptions: React.FC<Props> = (props) => {
  const {
    activeFilters,
    selectedFilterType,
    closeCategory,
    handleFilter,
    deleteFilters,
    isFetchingProducts,
    eCommerceCategory,
    excludeParams,
  } = props;

  const { currency } = useAppConfig();

  const localize = useLocalization();

  const filterIndexInActiveList = activeFilters.findIndex(item => (
    item.type === selectedFilterType.key
  ));

  const selectedFilterPrice = selectedFilterType.key === 'price';
  const selectedFilterPriceText = getFilterNamesWithCurrency(selectedFilterType.name, currency, localize);

  return (
    <Fragment>
      <div className='CatalogFiltersMobileOptions__header'>
        <button
          type='button'
          className='CatalogFiltersMobileOptions__headerBack'
          onClick={closeCategory}
        >
          <span className='CatalogFiltersMobileOptions__headerBackIcon icon-arrow-left' />
          <span className='CatalogFiltersMobileOptions__headerTitle'>
            {selectedFilterPrice
              ? selectedFilterPriceText
              : selectedFilterType.name
            }
          </span>
        </button>
        {filterIndexInActiveList !== -1 && !selectedFilterPrice &&(
          <button
            type='button'
            onClick={deleteFilters(selectedFilterType.key)}
            className='CatalogFiltersMobileOptions__headerButton'
          >
            <span>{localize('general.clear-all')}</span>
          </button>
        )}
      </div>
      <div className='CatalogFiltersMobileOptions__content'>
        <div className='CatalogFiltersMobileOptions__options'>
          <CheckList
            isMobileMode
            eCommerceCategory={eCommerceCategory}
            filter={selectedFilterType}
            handleFilter={handleFilter}
            hasSearch
            isFetchingProducts={isFetchingProducts}
            excludeParams={excludeParams}
          />
        </div>
      </div>

      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default CatalogFiltersMobileOptions;
