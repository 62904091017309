import { ReactNode } from 'react';
import typography from 'src/styles/typography';

export interface Props {
  children: ReactNode;
}

export default function HeadingH3(props: Props) {
  const { children } = props;

  return (
    <h2 className='HeadingH3'>
      {children}

      <style jsx>{`
        .HeadingH3 {
          ${typography.h3}
          margin-bottom: 8px;
        }
      `}</style>
    </h2>
  );
}
