import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Product } from "src/redux/apiTypes";
import checkExcisableAvailability from "src/redux/catalogue/catalogueOperations/checkExcisableAvailability";
import { trackRemarketingProducts } from "src/redux/googleAnalytics/googleAnalyticsActions";
import { useToggles } from "src/utils/toggles/useToggles";

interface Params {
  dataAreReady: boolean;
  categoryId: string;
  categoryProducts: Product[];
  place: string;
}

const useCheckExcisableAvailability = (params: Params) => {
  const {
    dataAreReady,
    categoryId,
    categoryProducts,
    place,
  } = params;

  const dispatch = useDispatch();
  const { AGE_CONFIRMATION_IS_ENABLED } = useToggles();

  useEffect(() => {
    if (dataAreReady) {
      dispatch(checkExcisableAvailability({
        selectedCategoryId: categoryId,
        AGE_CONFIRMATION_IS_ENABLED,
      }));
    }

    if (categoryProducts.length > 0) {
      dispatch(trackRemarketingProducts(categoryProducts, place));
    }

  }, [
    dataAreReady,
    categoryProducts,
    categoryId,
    AGE_CONFIRMATION_IS_ENABLED,
    place,
    dispatch,
  ]);
};

export default useCheckExcisableAvailability;
