import Head from 'next/head';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FilterableLayout from 'src/components/containers/FilterableLayout/FilterableLayout';
import FilterableLayoutHeaderSlot from 'src/components/containers/FilterableLayout/FilterableLayoutHeaderSlot';
import WithHorecaAuth from 'src/components/containers/WithAuthHoreca/WithAuthHoreca';
import { selectStoreId } from 'src/redux/cart/general/cartSelectors';
import { requestGetB2BProducts, requestLoadMoreB2BProducts } from 'src/redux/categories/categoriesOperations/getB2BProducts';
import { selectB2BData } from 'src/redux/categories/categoriesSelectors';
import { trackProductClick, trackProductImpressionEvent } from 'src/redux/googleAnalytics/googleAnalyticsActions';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import useBanners from 'src/swr/useBanners/useBanners';
import { addToCartButtonsEnum, impressionListEnum, productClickEventEnum } from 'src/utils/marketing/enhancedEcommerce';
import useCategoryLoadMoreHandler from '../../hooks/useCategoryLoadMoreHandler';
import { useResetPageForB2B } from '../../hooks/useResetPageForB2B';
import useUpdateB2BCategoryData from '../../hooks/useUpdateB2BCategoryData';

export interface Query {
  page: string;
}

const B2BPage = () => {
  const { isFetching, data: b2bData } = useAppSelector(selectB2BData);
  const language = useAppSelector(s => s.app.language);
  const baseURL = useAppSelector(s => s.app.baseURL);
  const storeId = useAppSelector(selectStoreId);
  const { count, results, filters, count_available } = b2bData;
  const dispatch = useDispatch();
  const localize = useLocalization();

  const categoryId =  'b2b-products';

  const banners = useBanners({
    place: 'category_page',
    storeId,
    categoryId,
    canFetch: true,
  });

  const title = localize('category_horeca_goods');

  useUpdateB2BCategoryData({
    updateAction: requestGetB2BProducts,
  });

  useResetPageForB2B(b2bData);

  const handleProductTileClick = (product, index) => {
    dispatch(trackProductClick(
      product,
      productClickEventEnum.B2B_PRODUCTS,
      index,
    ));
  };

  const onLoadMore = useCallback(async (urlString: string) => {
    await dispatch(requestLoadMoreB2BProducts(urlString));
  }, [dispatch]);

  const { handleLoadMore } = useCategoryLoadMoreHandler({
    onLoadMore,
  });

  const handleProductTileImpressed = (product, index) => {
    dispatch(trackProductImpressionEvent(
      product,
      impressionListEnum.B2B_PRODUCTS,
      index,
    ));
  };

  return (
    <WithHorecaAuth>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <FilterableLayout
        products={results}
        currentLocale={language}
        filters={filters}
        count={count}
        countAvailable={count_available}
        isFetching={isFetching}
        onProductTileClick={handleProductTileClick}
        onProductTileImpressed={handleProductTileImpressed}
        eCommerceCategory={impressionListEnum.PROMOTIONS}
        productPlace={addToCartButtonsEnum.PROMOTIONS}
        sliderSlot={banners}
        headerSlot={
          <FilterableLayoutHeaderSlot
            categoryId={categoryId}
            title={title}
            language={language}
            baseURL={baseURL}
          />
        }
        onLoadMore={handleLoadMore}
      />
    </WithHorecaAuth>
  );
};

export default B2BPage;
