import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoryUrl } from 'src/modules/Categories';
import useLocalization from 'src/services/localization/useLocalization';
import { Category, Product } from '../../../redux/apiTypes';
import setSearchBoxIsActive from '../../../redux/search/setSearchBoxIsActive';
import findCategoryObject from '../../../utils/categories/findCategoryObject';
import { sendCategoryClick } from '../../../utils/marketing/enhancedEcommerce';
import getPlace from '../../../utils/marketing/getPlace';
import Carousel from '../../views/Carousel/Carousel';
import GoodsNumber from '../../views/GoodsNumber/GoodsNumber';
import { styles } from './SearchResults.styles';
import SearchResultsProductRow from './SearchResultsProductRow';

interface Props {
  products: Product[];
  count: number;
  availableCount: number;
  categories: string[];
  allCategories: Category[];
  language: string;
  searchString: string;
  onViewAll: () => void;
  addToHistory: () => void;
  disableSearchBox: () => void;
}

interface Query {
  'category-id': string;
}

const SearchResults: FC<Props> = props => {
  const {
    products,
    count,
    availableCount,
    categories,
    allCategories,
    language,
    searchString,
    onViewAll,
    disableSearchBox,
    addToHistory,
  } = props;

  const localize = useLocalization();
  const dispatch = useDispatch();
  const router = useRouter<Query>();

  const handleViewAll = (event: React.MouseEvent) => {
    event.stopPropagation();
    onViewAll();
  };

  const getCategoryClickHandler = (categoryId: string) => async () => {
    await dispatch(setSearchBoxIsActive(false));
    addToHistory();
    sendCategoryClick(categoryId, getPlace(router, allCategories));
    disableSearchBox();
  };

  return (
    <div className='SearchResults'>

      {!!categories.length &&
        <Fragment>
          <span className='SearchResults__productsListTitle'>
            {localize('search.categories')}
          </span>

          <Carousel
            dataMarker='Categories Carousel'
          >
            {
              categories.map((categoryId, index) => {
                const category = findCategoryObject(allCategories, categoryId);

                if (!category) {
                  return null;
                }

                const encodedSearchQuery = encodeURIComponent(searchString);
                const href = `/[lang]/categories/[category-id]?q=${encodedSearchQuery}`;
                const as = getCategoryUrl({
                  language,
                  categoryId,
                });

                return (
                  <span
                    className='SearchResults__categoriesListItem'
                    key={categoryId}
                  >
                    <Link
                      href={href}
                      as={as}
                      onClick={getCategoryClickHandler(categoryId)}
                      data-marker='Category'
                      data-testid={`category_${index}`}
                    >
                      {category && category.title}
                    </Link>
                  </span>
                );
              })
            }
          </Carousel>
        </Fragment>
      }

      {!!categories.length && !!products.length &&
        <div className='SearchResults__separator' />
      }

      {!!products.length &&
        <span className='SearchResults__productsListTitle'>
          {localize('products.title')}
        </span>
      }

      {!!products.length &&
        <ul
          className='SearchResults__productsList'
          data-marker='Products List'
        >
          {
            products.map((product, index) => (
              <SearchResultsProductRow
                key={product.ean}
                product={product}
                index={index}
                addToHistory={addToHistory}
              />
            ))
          }
        </ul>
      }

      {count > 10 &&
        <button
          className='SearchResults__viewAll'
          onClick={handleViewAll}
          data-testid='viewAll'
          data-marker='View All'
        >
          {localize('general.show-all')}
          {' '}
          <GoodsNumber number={availableCount} />
        </button>
      }

      <style jsx>{styles}</style>
    </div>
  );
};

export default SearchResults;
