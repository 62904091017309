import css from 'styled-jsx/css';

export const styles = css`
  .CatalogActiveFiltersList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .CatalogActiveFiltersList__removeAll {
    margin: 0 20px 10px 0;
  }
  .CatalogActiveFiltersList__removeAll button {
    font-size: 14px;
  }
  .CatalogActiveFiltersList__filter {
    display: flex;
    align-items: center;
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
    border-radius: var(--border-radius-block);
    background-color: var(--colors-white);
    border: 1px solid var(--colors-brandLightGrey1);
  }
  .CatalogActiveFiltersList__filterButton {
    margin-left: 10px;
    font-size: 10px;
    background: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    color: var(--colors-darkGrey);
  }

  .CatalogActiveFiltersList__placeholderk {
    height: 30px;
    width: 60%;
    margin-bottom: 15px;
    animation: pulse 1.5s infinite;
    background-color: var(--colors-brandLightGrey1);
  }
  @-moz-keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  @-webkit-keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  @-o-keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  `;
