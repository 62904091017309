import { QueryString } from "src/redux/apiTypes";
import { RetailChain } from "src/redux/retailChain/types";

interface RetailInfoLinkUrlParams {
  language: string;
  retailChain: RetailChain;
  withDeliveryData: boolean;
  deliveryAsQueryStringForLink: QueryString;
}


const getRetailInfoLinkUrl = (params: RetailInfoLinkUrlParams) =>{
  const {
    language,
    retailChain,
    withDeliveryData,
    deliveryAsQueryStringForLink,
  } = params;

  if (withDeliveryData) {
    return `https://${retailChain.hostname}/${language}/${deliveryAsQueryStringForLink}`;
  }

  return `https://${retailChain.hostname}/${language}/`;
};

export default getRetailInfoLinkUrl;
