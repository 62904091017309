import { Address } from "src/redux/addressManagement/types";
import { QueryString } from "src/redux/apiTypes";
import { convertAddressToMainSiteDelivery } from "./convertAddressToMainSiteDelivery";

const getRetailInfoQueryStringForLink = (address: Address): QueryString => {

  //less then one because address may be contains only delivery type
  if (Object.keys(address).length <= 1) {
    return '';
  }

  const mainSiteUserDelivery = convertAddressToMainSiteDelivery(address);
  const queryString: QueryString = mainSiteUserDelivery
    ?`?delivery=${encodeURIComponent(JSON.stringify(mainSiteUserDelivery))}`
    : '';

  return queryString;
};

export default getRetailInfoQueryStringForLink;
