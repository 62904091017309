import { NpStreet } from "src/modules/AddressManagement/AddressManagement.types";
import { MainSiteDelivery } from "src/modules/AddressManagement/components/DeliveryUrlWatcher/types";
import { Address } from "src/redux/addressManagement/types";

export const convertAddressToMainSiteDelivery = (address: Address): MainSiteDelivery => {
  const deliveryType = address.delivery;
  const deliveryServiceId = address.addressData?.delivery_service || '';
  if (deliveryType === 'pickup') {
    return {
      type: deliveryType,
      deliveryServiceId,
    };
  }

  const coordsArray = address.coords?.split(', ') || [];

  if (deliveryType === 'plan') {
    const [lat, lng] = coordsArray.map(c => Number(c));
    return {
      type: deliveryType,
      deliveryServiceId,
      geocodedAddress: {
        city: address.city,
        building: address.building,
        street: address.street as string,
        coords: { lat, lng },
      },
    };
  }



  if (deliveryType === 'nova_poshta') {
    const [lat, lng] = coordsArray;
    const coords = { lat, lng };
    const { id: settlementId, warehouse, label: settlementLabel, address_delivery_not_allowed } = address.locality;
    const { id: warehouseId, max_weight_allowed, number, label: warehouseName, type } = address.department;

    return {
      type: deliveryType,
      settlement: { id: settlementId, warehouse: warehouse, coords, name: settlementLabel, address_delivery_not_allowed },
      warehouse: {
        id: warehouseId,
        max_weight_allowed,
        number, coords,
        name: warehouseName,
        settlement_id: settlementId,
        type,
      },
    };
  }

  if (deliveryType === 'nova_poshta_address') {
    const { building } = address;
    const street = address.street as NpStreet;

    const [lat, lng] = coordsArray;
    const coords = { lat, lng };
    const { id: settlementId, warehouse, label: settlementLabel, address_delivery_not_allowed } = address.locality;

    return {
      type: deliveryType,
      settlement: { id: settlementId, warehouse: warehouse, coords, name: settlementLabel, address_delivery_not_allowed },
      building,
      street,
    };
  }

  return null;
};
