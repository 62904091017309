import { Address } from "src/redux/addressManagement/types";
import { QueryString } from "src/redux/apiTypes";

export const getRetailsInfoRequestQueryString = (address: Address): QueryString => {
  let queryString: QueryString = ``;

  //less then one because address may be contains only delivery type
  if (Object.keys(address).length <= 1) {
    return queryString;
  }

  const deliveryType = address.delivery;
  const deliveryService = address.addressData?.delivery_service;

  if (deliveryType === 'pickup') {
    queryString = `?include_delivery=1&delivery_service_id=${deliveryService}`;
  }

  const coords = address.coords?.replace(' ', '');

  if (deliveryType === 'plan') {
    queryString = `?include_delivery=1&coords=${coords}&delivery_service_id=${deliveryService}`;
  }

  if (deliveryType?.includes('nova_poshta')) {
    queryString = `?include_delivery=1&coords=${coords}&settlement_id=${address.locality?.id}`;
  }

  return queryString;
};
