import { useRouter } from 'next/router';
import ReactPlaceholder from 'react-placeholder';
import Button from 'src/components/ui-kit/Button/Button';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import getParsedFilterPriceValue from 'src/utils/filter/getParsedFilterPriceValue';
import getPriceAndCurrencyText from 'src/utils/filter/getPriceAndCurrencyText';
import deleteFilters from '../../../utils/filter/deleteFilters';
import toggleFilter from '../../../utils/filter/toggleFilter';
import { styles } from './CatalogActiveFiltersList.styles';
import { Facet } from './getActiveFilters';

export interface CatalogActiveFiltersListProps {
  filters: Facet[];
  excludeParams: string[];
  isFetchingProducts: boolean;
}

const CatalogActiveFiltersList = (props: CatalogActiveFiltersListProps) => {
  const {
    filters = [],
    excludeParams = [],
    isFetchingProducts,
  } = props;

  const { currency } = useAppConfig();

  const router = useRouter();
  const localize = useLocalization();

  const clearAll = () => {
    deleteFilters(null, router, excludeParams);
  };

  const deleteFilter = (type: string, value: string) => () => {
    toggleFilter(type, value, router, excludeParams);
  };

  const shouldShowContent =
    !isFetchingProducts || (!!filters && filters.length > 0);


  if (filters.length <= 0) {
    return null;
  }

  return (
    <ReactPlaceholder
      ready={shouldShowContent}
      className='CatalogActiveFiltersList__placeholder'
      customPlaceholder={<div />}
    >
      <div className='CatalogActiveFiltersList'>
        <div className='CatalogActiveFiltersList__removeAll'>
          <Button
            variant='ghost'
            dataMarkerValue='Clear all filters'
            onClick={clearAll}
          >
            {localize('filters.clear-all')}
          </Button>
        </div>
        {filters.map(filter =>
          filter.type === 'price'
            ? <div key={filter.type} className='CatalogActiveFiltersList__filter'>
                <span>
                  {getPriceAndCurrencyText({
                    price: getParsedFilterPriceValue(filter.price),
                    localize,
                    currency,
                  })}
                </span>
                <button
                  type='button'
                  className='icon-close CatalogActiveFiltersList__filterButton'
                  data-marker='Close filter'
                  onClick={deleteFilter(filter.type, filter.price)}
                />
            </div>
            : <div key={filter.option.value} className='CatalogActiveFiltersList__filter'>
                <span>{filter.option.name}</span>
                <button
                  type='button'
                  className='icon-close CatalogActiveFiltersList__filterButton'
                  data-marker='Close filter'
                  onClick={deleteFilter(filter.type, filter.option.value)}
                />
            </div>,
          )
        }
      </div>

      <style jsx>{styles}</style>
    </ReactPlaceholder>
  );
};

export default CatalogActiveFiltersList;
