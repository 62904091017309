import Link from 'next/link';
import { sendViewAllTileClick } from 'src/utils/marketing/enhancedEcommerce';
import { MainLink } from '../SliderWrapper/types';
import { styles } from './SeeMoreCard.styles';

const SeeMoreCard = (props: MainLink) => {
  const {
    href,
    title,
  } = props;

  return (
    <Link
      href={href}
      onClick={sendViewAllTileClick}
      data-marker='View all - tile'
    >
      <div className='SeeMoreCard'>
        <i className='SeeMoreCard__icon icon-arrow-up-right' />
        <div className='SeeMoreCard__title'>
          {title}
        </div>

        <style jsx>{styles}</style>
      </div>
    </Link>
  );
};

export default SeeMoreCard;
