import { useDispatch, useSelector } from 'react-redux';
import Responsive from 'src/components/views/Responsive/Responsive';
import useLocalization from 'src/services/localization/useLocalization';
import selectAddress from '../../../../../redux/cart/general/selectAddress';
import { openModal } from '../../../../../redux/modals';
import { RootState } from '../../../../../redux/reducers';
import { getDeliveryDataFromAddress } from '../../../../../utils/delivery/getDeliveryDataFromAddress';
import getDeliveryText from '../../../../../utils/delivery/getDeliveryText';
import { styles } from './AddressButton.styles';


const AddressButton = () => {
  const localize = useLocalization();
  const dispatch = useDispatch();

  const address = useSelector(selectAddress);
  const cartType = useSelector((s: RootState) => s.app.cartType);
  const serverCartData = useSelector((s: RootState) => s.serverCart.data);

  if (!cartType) {
    return null;
  }

  const deliveryData = getDeliveryDataFromAddress(address);
  const deliveryText = getDeliveryText(deliveryData, localize) || localize('address.select-new-2');

  const storeIdOfSelectedDeliveryMethod =
    (cartType === 'client'
      ? address?.addressData?.id
      : serverCartData?.store_id) || 'default';

  const handleClick = () => {
    dispatch(openModal('addressModal'));
  };

  return (
    <div
      className='AddressButton'
      onClick={handleClick}
      role='button'
      data-marker='Address'
      data-marker-store-id={storeIdOfSelectedDeliveryMethod}
      tabIndex={0}
    >
      <span className='AddressButton__icon icon-pin' />
      <Responsive tabletDesktop>
        <span className='AddressButton__label'>{deliveryText}</span>
      </Responsive>
      <style jsx>{styles}</style>
    </div>
  );
};

export default AddressButton;
