import css from 'styled-jsx/css';
import { desktop, phone, tablet } from '../../../styles/media';

export const styles = css`
  .ProductsCarousel {
    margin: 0 0 60px;
    width: 100%;
  }
  .ProductsCarousel_withoutMarginBottom {
    margin-bottom: 0;
  }
  .ProductsCarousel__item {
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    border-color: var(--colors-brandLightGrey1);
    border-width: 1px 0 1px 1px;
    border-style: solid;
  }
  .ProductsCarousel__item:last-child {
    border-width: 1px;
  }
  .ProductsCarousel__item_withoutBorder {
    border-width: 0;
  }
  .ProductsCarousel__item_withoutBorder:last-child {
    border-width: 0;
  }
  .ProductsCarousel__item:hover {
    z-index: 2;
  }
  .ProductsCarousel .slider__container {
    margin-right: -1px;
  }
  .ProductsCarousel__item_flexWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  @media ${desktop} {
    .ProductsCarousel__item {
      width: 20%;
    }
    .ProductsCarousel__item_wide {
      width: 25%;
    }
    .ProductsCarousel__item_flexWrapper {
      width: 100%;
    }
  }
  @media ${tablet} {
    .ProductsCarousel__item {
      width: 25%;
    }
    .ProductsCarousel__item_wide {
      width: 25%;
    }
    .ProductsCarousel__item_flexWrapper {
      width: 100%;
    }
  }
  @media ${phone} {
    .ProductsCarousel {
      margin-left: -10px;
      margin-right: -10px;
      width: auto;
    }
    .ProductsCarousel__item {
      width: 25%;
      min-width: 162px;
    }
  }
`;
