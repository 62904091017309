import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { Fragment, ReactNode } from 'react';
import ContentSorterDropdown from 'src/components/views/ContentSorterDropdown/ContentSorterDropdown';
import MainSlider, { SliderPlace } from 'src/components/views/MainSlider/MainSlider';
import { excludeParams } from 'src/modules/Categories';
import useLocalization from 'src/services/localization/useLocalization';
import { Banners } from 'src/swr/useBanners/types';
import GeneralPagesLayout from '../../../layouts/GeneralPagesLayout';
import { Filter, Product } from '../../../redux/apiTypes';
import deleteFilters from '../../../utils/filter/deleteFilters';
import { getDefaultSort, getSortList, navigateToSort } from '../../../utils/filter/sortContent';
import toggleFilter from '../../../utils/filter/toggleFilter';
import Container from '../../views/Container/Container';
import GoodsNumber from '../../views/GoodsNumber/GoodsNumber';
import PageWrap from '../../views/PageWrap/PageWrap';
import PageWrapAside from '../../views/PageWrapAside/PageWrapAside';
import PageWrapBody from '../../views/PageWrapBody/PageWrapBody';
import Responsive from '../../views/Responsive/Responsive';
import CatalogActiveFiltersList from '../CatalogActiveFiltersList/CatalogActiveFiltersList';
import getActiveFilters from '../CatalogActiveFiltersList/getActiveFilters';
import CatalogFilters, { GetHandlerDeleteFiltersFunc, HandleFilterFunc } from '../CatalogFilters/CatalogFilters';
import CategoryProductBox from '../CategoryProductBox/CategoryProductBox';
import { SortItem } from '../ContentSorterModal/ContentSorterModalView';
import EmptyCartWatcher from '../EmptyCartWatcher/EmptyCartWatcher';
import EmptyCategory from '../EmptyCategory/EmptyCategory';
import { styles } from './FilterableLayout.styles';

export interface FilterableLayoutProps {
  products: Product[];
  filters: Filter[];
  count: number;
  countAvailable: number;
  isFetching: boolean;
  emptyMessageSlot?: ReactNode;
  headerSlot: ReactNode;
  sliderSlot?: Banners;
  eCommerceCategory: string;
  productPlace: string;
  onProductTileImpressed: (product: Product, index: number) => void;
  onProductTileClick: (product: Product, index: number) => void;
  currentLocale: string;
  onLoadMore: () => void;
  filtersAsLinks?: boolean;
}

interface Query extends ParsedUrlQuery {
  sort: string;
}

const FilterableLayout = (props: FilterableLayoutProps) => {
  const {
    products = null,
    filters = [],
    count,
    countAvailable,
    isFetching,
    emptyMessageSlot,
    headerSlot,
    sliderSlot,
    onProductTileClick,
    onProductTileImpressed,
    eCommerceCategory,
    productPlace,
    currentLocale,
    onLoadMore,
    filtersAsLinks,
  } = props;

  const localize = useLocalization();
  const router = useRouter<Query>();

  const activeFilters = getActiveFilters(router.query, filters);

  const showFilters = (filters && count > 0) || activeFilters.length !== 0;

  const handleFilter: HandleFilterFunc = (type, name) => () => {
    toggleFilter(type, name, router, excludeParams);
  };
  const getHandlerDeleteFilters: GetHandlerDeleteFiltersFunc = (type) => () => {
    deleteFilters(type, router, excludeParams);
  };

  const catalogFiltersProps = {
    withStickyFilters: true,
    showFilters,
    filters,
    activeFilters: activeFilters,
    handleFilter: handleFilter,
    isFetchingProducts: isFetching,
    eCommerceCategory: eCommerceCategory,
    excludeParams: excludeParams,
    deleteFilters: getHandlerDeleteFilters,
    fixedMenu: !showFilters,
  };

  const sortList = getSortList(router.pathname);
  const defaultSort = getDefaultSort(sortList);
  const activeSortKey = router.query.sort || defaultSort;
  const activeSort =
    sortList.find((s) => s.value === activeSortKey) ||
    sortList.find((s) => s.value === defaultSort); // In case of wrong activeSortKey
  const productsCount = countAvailable ? countAvailable : count;

  const handleSort = (data: SortItem) => {
    navigateToSort(router, data.value, excludeParams);
  };

  const isEmptyCategory = !isFetching && count === 0;
  const sortingIsVisible = count > 0;

  const bannerSliderProps = {
    place: 'category' as SliderPlace,
    language: currentLocale,
    banners: sliderSlot?.data || [],
    isFetching: !!sliderSlot?.isFetching,
    hasError: !!sliderSlot?.errors?.length,
  };

  const isVisibleSortingWrapper = products.length > 0 || sortingIsVisible;

  return (
    <GeneralPagesLayout {...catalogFiltersProps} withNotifyGamify >
      <Container>
        <div className="FilterableLayout">
          { !isEmptyCategory && headerSlot }
          <PageWrap >
            <Responsive desktop displayNoneMode>
              <PageWrapAside>
                {showFilters && (
                  <CatalogFilters
                    filters={filters}
                    isFetchingProducts={isFetching}
                    eCommerceCategory={eCommerceCategory}
                    excludeParams={excludeParams}
                    filtersAsLinks={filtersAsLinks}
                  />
                )}
              </PageWrapAside>
            </Responsive>
            <PageWrapBody>
              { isEmptyCategory && headerSlot }
              <MainSlider {...bannerSliderProps} />
              <div className='search-result'>

                <Fragment>
                  {isVisibleSortingWrapper && (
                    <div className='FilterableLayout__sortingWrapper'>
                      {
                        products.length > 0 && (
                          <span className='FilterableLayout__productsCount'>
                            <GoodsNumber number={productsCount} />
                          </span>
                        )
                      }
                      {
                        sortingIsVisible && (
                          <Responsive desktop>
                            <ContentSorterDropdown
                              label={`${localize('filters.what-to-show')}:`}
                              filters={sortList.map(s => ({
                                label: localize(s.translation_key),
                                value: s.value,
                              }))}
                              onChange={handleSort}
                              active={{
                                label: localize(activeSort.translation_key),
                                value: activeSort.value,
                              }}
                            />
                          </Responsive>
                        )
                      }
                    </div>
                  )}

                  <CatalogActiveFiltersList
                    filters={activeFilters}
                    isFetchingProducts={isFetching}
                    excludeParams={['lang', 'category']}
                  />

                  {isEmptyCategory
                    ? <EmptyCategory
                      excludeParams={excludeParams}
                      activeFilters={activeFilters.length !== 0}
                      emptyMessageSlot={emptyMessageSlot}
                    />
                    : (
                      <CategoryProductBox
                        products={products}
                        totalCount={count}
                        isFetchingProducts={isFetching}
                        onProductTileClick={onProductTileClick}
                        onProductTileImpressed={onProductTileImpressed}
                        excludeParams={excludeParams}
                        productPlace={productPlace}
                        onLoadMore={onLoadMore}
                      />
                    )
                  }

                </Fragment>

              </div>
            </PageWrapBody>
          </PageWrap>
        </div>
      </Container>

      <EmptyCartWatcher />
      <style jsx>{styles}</style>
    </GeneralPagesLayout>
  );
};

export default FilterableLayout;
