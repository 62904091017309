import { Product } from "src/redux/apiTypes";
import { selectStoreId } from "src/redux/cart/general/cartSelectors";
import selectAddress from "src/redux/cart/general/selectAddress";
import { useAppSelector } from "src/redux/hooks";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import useSWR from "swr";
import { requestGetFiltersLikeCategoriesProducts } from "../ajax/requestGetFiltersLikeCategoriesProducts";

const emptyArray: Product[] = [];

const useCustomCategoryProducts = (categoryId: string, fallbackData: Product[] = emptyArray) => {
  const { fetchConfig } = useAppConfig();
  const storeId = useAppSelector(selectStoreId);
  const deliveryType = useAppSelector(selectAddress).delivery;
  const isLoggedIn = useAppSelector(s => s.user.isLoggedIn);
  const queryString = `only_items=true`;

  const customCategoryDataFetcher = async () => {
    const result = await requestGetFiltersLikeCategoriesProducts({
      fetchConfig,
      storeId,
      categoryId,
      deliveryType,
      queryString,
    });

    return result?.results || emptyArray;
  };

  const { data, error, mutate } = useSWR<Product[]>(
    [storeId, categoryId, deliveryType, queryString, isLoggedIn],
    customCategoryDataFetcher,
    {
      shouldRetryOnError: false,
      fallbackData,
    },
  );

  return {
    data: data || emptyArray,
    isFetching: !data && !error,
    mutate,
  };
};

export default useCustomCategoryProducts;
