import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import Responsive from 'src/components/views/Responsive/Responsive';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { useAppLinks } from 'src/utils/marketing/appLinks/useAppLinks';
import { useToggles } from 'src/utils/toggles/useToggles';
import SocialNetworksBlock from '../../../modules/Socials/components/SocialNetworksBlock/SocialNetworksBlock';
import { ADMITAD_COOKIE_NAME, SALESDOUBLER_COOKIE_NAME } from '../../../redux/constants';
import Container from '../../views/Container/Container';
import FooterMenu from '../../views/FooterMenu/FooterMenu';
import GoToTopButton from '../../views/GoToTopButton/GoToTopButton';
import ZakazBanner from '../../views/ZakazBanner/ZakazBanner';
import ZsuHelp from '../../views/ZsuHelp/ZsuHelp';
import ChooseLanguage from '../ChooseLanguage/ChooseLanguage';
import FooterAppsLinks from '../FooterAppsLinks/FooterAppsLinks';
import FooterCertificate from '../FooterCertificate/FooterCertificate';
import FooterContacts from '../FooterContacts/FooterContacts';
import { styles } from './Footer.styles';
import replaceTemplateWithCurrentYear from './replaceTemplateWithCurrentYear';

export const Footer = () => {
  const localize = useLocalization();
  const { host, country, imgRoot } = useAppConfig();
  const copyright = replaceTemplateWithCurrentYear(localize('copyright_text'), '%year%');
  const applinks = useAppLinks();
  const hasAppsLinks = !!(applinks.ios || applinks.android);

  const { CERTIFICATE_HACCP } = useToggles();

  const [appLinksAvailable, setAppLinksAvailable] = useState(hasAppsLinks);

  const servicePagesList = useAppSelector(state => state.servicePages.data);
  const language = useAppSelector(state => state.app.language);

  useEffect(() => {
    const admitadUID = Cookies.get(ADMITAD_COOKIE_NAME);
    const salesDoublerUID = Cookies.get(SALESDOUBLER_COOKIE_NAME);
    const appLinksAvailable = hasAppsLinks && !admitadUID && !salesDoublerUID;

    setAppLinksAvailable(appLinksAvailable);
  }, [hasAppsLinks]);

  return (
    <footer className='Footer' data-marker='Footer'>
      <div className='Footer__main'>
        <Container>
          <div className='Footer__mainInner'>
            <div className='Footer__block Footer__logo'>
              <div className="Footer__blockLine">
                <ZakazBanner locale={language} />
              </div>
              <Responsive phoneTablet >
                <div className="Footer__blockLine">
                  <ChooseLanguage/>
                </div>
                <div className="Footer__blockLine">
                  <ZsuHelp notAccented />
                </div>
              </Responsive>
            </div>
            <div className='Footer__block Footer__menu'>
              <FooterMenu
                host={host}
                items={servicePagesList}
                menuTitle={`zakaz.${country}`}
              />
            </div>
            <div className='Footer__block Footer__contacts'>
              <FooterContacts title={localize('footer.contacts')} />

              <div className="Footer__socials">
                <SocialNetworksBlock
                  theme='light'
                  dataMarker='Footer Socials'
                />
              </div>
            </div>

            {(appLinksAvailable || CERTIFICATE_HACCP) &&
              <div className='Footer__block'>
                {appLinksAvailable &&
                  <FooterAppsLinks
                    title={localize('download_app')}
                    appLinks={{
                      android: applinks.android,
                      ios: applinks.ios,
                    }}
                  />
                }

                {CERTIFICATE_HACCP &&
                  <FooterCertificate imgRoot={imgRoot} />
                }
              </div>
            }
          </div>
        </Container>
      </div>
      <div className='Footer__bottom'>
        <Container>
          <div className='Footer__bottomInner'>
            <span className='Footer__copyright' data-testid='footerCopyright' >
              {copyright}
            </span>
            <GoToTopButton />
          </div>
        </Container>
      </div>
      <style jsx>{styles}</style>
    </footer>
  );
};

export default Footer;
