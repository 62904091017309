import { useDispatch } from 'react-redux';
import ProductsSliderWithPlaceholder from 'src/components/views/ProductsSliderWithPlaceholder/ProductsSliderWithPlaceholder';
import useProductsCarouselProps from 'src/hooks/useProductsCarouselProps/useProductsCarouselProps';
import { Product } from 'src/redux/apiTypes';
import { trackProductImpressionEvent } from 'src/redux/googleAnalytics/googleAnalyticsActions';
import useLocalization from 'src/services/localization/useLocalization';
import { sendViewProductsListEvent } from 'src/utils/marketing/enhancedEcommerce';
import { getCustomCategoryUrl } from '../../helpers/customCategoryPageHelpers';
import useCustomCategoryProducts from '../../hooks/useCustomCategoryProducts';

export interface Props {
  place: string;
  initProducts?: Product[];
  handlersPlace?: string;
}

const slidesToShowOnScreen = 5;

export default function PromotionsCarousel(props: Props) {
  const { place, handlersPlace = place, initProducts } = props;

  const localize = useLocalization();
  const dispatch = useDispatch();
  const categoryId = 'promotions';

  const { data: promotions, isFetching } = useCustomCategoryProducts(categoryId, initProducts);

  const title = localize('products.discounts');
  const slicedPromotions = promotions.length > slidesToShowOnScreen
    && promotions.length % slidesToShowOnScreen === 0
      ? promotions.slice(0, -1)
      : promotions;

  const carouselProps = useProductsCarouselProps({
    items: slicedPromotions,
    place,
    handlersPlace,
    title,
  });
  const language = carouselProps.language;
  const itemsToBeShown = carouselProps.items;

  const handleProductTileImpressed = (product: Product, index: number) => {
    dispatch(
      trackProductImpressionEvent(
        product,
        place,
        index,
      ),
    );
  };

  const handleImpressedSlider = () => sendViewProductsListEvent(categoryId, title, itemsToBeShown);

  return <ProductsSliderWithPlaceholder
    carouselProps={{
      ...carouselProps,
      onProductTileImpressed: handleProductTileImpressed,
      dataSuffix: categoryId,
      buttonUrl: getCustomCategoryUrl({language, categoryId}),
      withShowAllButton: true,
    }}
    placeholderTitle={title}
    placeholderShouldBeDisplayed={isFetching}
    sliderShouldNotDisplayed={slicedPromotions.length === 0}
    onImpressed={handleImpressedSlider}
  />;
}
