
import classNames from 'classnames';
import useLocalization from 'src/services/localization/useLocalization';
import { useToggles } from 'src/utils/toggles/useToggles';
import { styles } from './ZsuHelp.styles';

interface Props {
  notAccented?: boolean;
}

const ZsuHelp = (props: Props) => {
  const { notAccented } = props;
  const localize = useLocalization();
  const { ZSU_HELP_IS_AVAILABLE } = useToggles();

  if (ZSU_HELP_IS_AVAILABLE) {
    return (
      <a
        href="https://bank.gov.ua/ua/about/support-the-armed-forces"
        target="blank"
        rel="noreferrer nofollow"
        data-marker="Zsu Button"
        className={classNames('ZsuHelp', { ZsuHelp_notAccented: notAccented })}
      >
        {localize('zsu_btn')}
        <span className={classNames("ZsuHelp__icon icon-arrow-up-right", { ZsuHelp__icon_notAccented: notAccented })} />
        <style jsx>{styles}</style>
      </a>
    );
  }

  return null;
};

export default ZsuHelp;
