import { FetchConfig } from 'src/redux/apiTypes';
import { RetailChain } from 'src/redux/retailChain/types';

export async function getRetailChains(config: FetchConfig): Promise<RetailChain[]> {
  const { apiRoot, xVersion } = config;
  const url = `${apiRoot}/retail_chains/?accepts_excise_payment_online=true`;
  const response = await fetch(
    url,
    {
      headers: {
        'x-version': xVersion,
      },
    },
  );

  if (!response.ok) {
    throw new Error(`An error occurred while fetching the data: ${url}`);
  }

  return await response.json();
}
