import { Product } from '../../../redux/apiTypes';
import CartProductTile from '../CartProductTile/CartProductTile';
import { styles } from './ProductsBox.styles';

export interface ProductTileActionHandler {
  (product: Product, index: number): void;
}

interface Props {
  title?: string;
  items: Product[];
  onProductTileClick: ProductTileActionHandler;
  onProductTileImpressed: ProductTileActionHandler;
  productPlace?: string;
}

const ProductsBox = (props: Props) => {
  const {
    title = null,
    items = null,
    onProductTileClick,
    onProductTileImpressed,
    productPlace,
  } = props;

  const handleProductTileClick = (product, index) => () => {
    onProductTileClick(product, index);
  };

  const handleProductTileImpressed = (product, index) => () => {
    onProductTileImpressed(product, index);
  };

  return (
    <div className='ProductsBox' data-marker='Products Box'>
      {title && <h2 className='ProductsBox__title'>{title}</h2>}
      {items && (
        <div className='ProductsBox__list'>
          {items.map((item, index) => {
            return (
              <div key={item.ean} className='ProductsBox__listItem'>
                <CartProductTile
                  data={item}
                  onClick={handleProductTileClick(item, index)}
                  onImpressed={handleProductTileImpressed(item, index)}
                  productPlace={productPlace}
                />
              </div>
            );
          })}
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductsBox;
